<template>
	<div class="detail-total">
		<div class="content" v-show="show">
			<div class="top-bar">
				<div class="left">
					<div v-if="object.imgArray.length != 0" class="image">
						<video style="width: 100%; height: 100%; object-fit: fill"
							v-if="object.imgArray[now_image_index].isMp4" :src="object.imgArray[now_image_index].url"
							controls="controls" loop muted autoplay>
							您的浏览器不支持 video 标签。
						</video>
						<img style="width: 100%; height: 100%; object-fit: fill" v-else
							:src="object.imgArray[now_image_index].url" />
					</div>
					<div class="child-image-bar">
						<img @click="clickArrow(1)" src="../assets/kh/jt_zuo@2x.png" class="arrow-left" />
						<div class="child-image-array">
							<div class="child-image" v-for="(item, index) in object.imgArray.length >=
                5 * now_image_page
                  ? object.imgArray.slice(0, 5)
                  : object.imgArray.slice(
                      (now_image_page - 1) * 5,
                      object.imgArray.length
                    )" :key="item.url">
								<video style="width: 100%; height: 100%; object-fit: fill" v-if="item.isMp4"
									:src="item.url" @click="clickImage(index + (now_image_page - 1) * 5)">
									您的浏览器不支持 video 标签。
								</video>
								<img v-else :src="item.url" @click="clickImage(index + (now_image_page - 1) * 5)"
									style="width: 100%; height: 100%; object-fit: fill" />
							</div>
						</div>
						<img @click="clickArrow(2)" src="../assets/kh/jt_you@2x.png" class="arrow-right" />
					</div>
				</div>
				<div class="right">
					<div class="title">
						{{ object.title }}
					</div>
					<div class="gray-bar">
						<div class="price-bar">
							<div class="price-group" v-if="object.price != 0">
								<div class="price-rmb">RMB</div>
								<div class="price">{{ object.price }}</div>
								<div class="price-tag">起</div>
							</div>
							<div class="price-group" v-else>
								<div class="price">
									{{ object.isfree ? "免费景区" : "敬请期待" }}
								</div>
							</div>
							<el-popover placement="top-start" width="400" trigger="hover" v-if="productType == 2">
								<div>
									<div>起价说明</div>
									<div>1、本起价是在可售日期范围内的最低价。</div>
									<div>
										2、产品价格会根据您所选的出发日期、出行人数等而有所差别。
									</div>
									<div>3、此最低价格已是优惠促销后的价格。</div>
									<div v-if="object.confirm == 1">
										<br />
										<div>一次确认</div>
										<div>
											本产品展示价格即最终购买价格，您可直接下单购买，成功付款后我们将尽快与您联系，如有任何疑问可直接联系客服
										</div>
									</div>
								</div>
								<div slot="reference" class="price-start-group">
									<div class="price-start">起价说明</div>
									<img src="../assets/kh/ico_qjsm@2x.png" class="price-start-icon" />
								</div>
							</el-popover>
							<div class="pro-number">编号{{ object.id }}</div>
						</div>
						<div class="service-bar">
							<div class="service-tag">{{ object.service_tag }}</div>
							<div class="service-type">{{ object.service_type }}</div>
							<div class="service-content">{{ object.service_content }}</div>
						</div>
					</div>
					<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/bq_kh%402x.png" class="prize"
						v-if="productType == 1 && $app == 'kh'" />
					<img v-else src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/bq_kh%402x.png" class="prize"
						v-else-if="productType == 1 && $app == 'dy'" />

					<div v-if="productType == 1 || productType == 3" class="group-theme">
						<div class="item-theme" v-for="item in object.theme_array" :key="item.id">
							<img :src="item.url" class="item-theme-img" />
							<div class="item-theme-value">{{ item.value }}</div>
							<div class="item-theme-title">{{ item.title }}</div>
						</div>
					</div>
					<div class="ticket-theme" v-else>
						<div class="item-theme" v-for="item in object.theme_array" :key="item.id">
							<img :src="item.url" class="item-theme-img" />
							<div class="item-theme-value">{{ item.value }}</div>
						</div>
					</div>

					<el-popover placement="right" width="200" trigger="click" v-if="object.price != 0">
						<div style="
                display: flex;
                flex-direction: column;
                width: 200px;
                height: 225px;
                align-items: center;
              ">
							<div style="
                  font-size: 14px;
                  line-height: 24px;
                  color: #333333;
                  text-align: center;
                  margin-top: 10px;
                  opacity: 1;
                ">
								<div>{{ wx_code == "" ? "加载中" : "请使用微信扫一扫" }}</div>
								<div>{{ wx_code == "" ? "" : "识别二维码预定" }}</div>
							</div>
							<img v-show="wx_code != ''" style="margin-top: 10px; width: 150px; height: 150px"
								:src="wx_code" />
						</div>
						<div slot="reference" class="btn-buy" @click="clickBuy">
							立即预定
						</div>
					</el-popover>
					<div v-else class="btn-buy-gray">立即预定</div>

					<img v-if="$app == 'kh'" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/zxdh_dz.png"
						class="buy-icon" />
					<!-- 大运判断地区 -->
					<section v-else-if="$app == 'dy'">
						<img v-if="org == 1"
							src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dlzxdh_dz%25402x.png"
							class="buy-icon" />
						<img v-else-if="org == 2"
							src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/jlzxdh_dz%25402x.png"
							class="buy-icon" />
						<img v-else-if="org == 3"
							src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/hrbzxdh_dz%25402x.png"
							class="buy-icon" />
						<img v-else src="https://jiaqi.obs.myhuaweicloud.com/pc_dy/zxdh_dz%402x.png" class="buy-icon" />
					</section>
				</div>
			</div>
			<div class="line"></div>
			<div class="tab-banner">
				<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
					@select="handleSelect" v-if="productType == 1">
					<el-menu-item index="1">商品详情</el-menu-item>
					<el-menu-item index="2">费用说明</el-menu-item>
					<el-menu-item index="3">其他说明</el-menu-item>
				</el-menu>
				<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
					@select="handleSelect" v-else-if="productType == 3">
					<el-menu-item index="1">商品详情</el-menu-item>
					<el-menu-item index="2">费用说明</el-menu-item>
				</el-menu>
				<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
					@select="handleSelect" v-else-if="productType == 60">
					<el-menu-item index="1">景点介绍</el-menu-item>
					<el-menu-item index="2">费用说明</el-menu-item>
				</el-menu>
				<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
					@select="handleSelect" v-else>
					<el-menu-item index="1">景点介绍</el-menu-item>
					<el-menu-item index="2">特惠政策</el-menu-item>
					<el-menu-item index="3">信息提示</el-menu-item>
					<el-menu-item index="4">温馨提示</el-menu-item>
					<el-menu-item index="5">交通指南</el-menu-item>
				</el-menu>
			</div>
			<div id="MENU1" class="info-banner" v-if="productType == 60">
				<img src="../assets/kh/ico_jdjs@2x.png" class="info-icon" />
				<div class="info-title">景点介绍</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[0]"></div>
			</div>
			<div id="MENU2" class="info-banner" v-if="productType == 60">
				<img src="../assets/kh/ico_qtxx@2x.png" class="info-icon" />
				<div class="info-title">其他信息</div>
				<div class="info-line"></div>
				<div class="info-content-banner">
					<div class="info-content-item" v-for="item in object.other_array" :key="item.name">
						<div class="info-content-title">{{ item.name }}</div>
						<div class="info-content-value" v-html="item.value"></div>
						<div class="info-content-line"></div>
					</div>
				</div>
			</div>
			<div id="MENU1" class="info-banner" v-if="productType == 1 || productType == 3">
				<img src="../assets/kh/ico_jbxx@2x.png" class="info-icon" />
				<div class="info-title">基本信息</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.basic_info"></div>
			</div>
			<div class="info-banner" v-if="productType == 1 || productType == 3">
				<img src="../assets/kh/ico_xcjs@2x.png" class="info-icon" />
				<div class="info-title">行程介绍</div>
				<div class="info-line"></div>
				<div class="trip-banner">
					<div v-if="object.trip_array.length == 0" class="trip-null">无</div>
					<div class="trip-item" v-for="item in object.trip_array" :key="item.id">
						<div class="trip-title-banner">
							<img src="../assets/kh/ico_xcjs_cf@2x.png" class="trip-title-icon" />
							<div class="trip-title">{{ item.title }}</div>
						</div>
						<div class="trip-title-banner">
							<img src="../assets/kh/ico_xcjs_jd@2x.png" class="trip-title-icon" />
							<div :class="productType == 1 ? 'trip-content' : 'trip-content-ship'" v-html="item.info">
							</div>
						</div>
						<div class="trip-title-banner">
							<img src="../assets/kh/ico_xcjs_cy@2x.png" class="trip-title-icon" />
							<div class="trip-content2">{{ item.food }}</div>
						</div>
						<div class="trip-title-banner">
							<img src="../assets/kh/ico_xcjs_lg@2x.png" class="trip-title-icon" />
							<div class="trip-content2">{{ item.inn }}</div>
						</div>
						<div class="trip-line"></div>
					</div>
				</div>
			</div>
			<div class="info-banner" v-if="productType == 1 || productType == 3">
				<img src="../assets/kh/ico_cpgk@2x.png" class="info-icon" />
				<div class="info-title">产品概括</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.product_desc == '' ? '无' : object.product_desc"></div>
			</div>
			<div id="MENU2" class="info-banner" v-if="productType == 1 || productType == 3">
				<img src="../assets/kh/ico_fysm@2x.png" class="info-icon" />
				<div class="info-title">费用说明</div>
				<div class="info-line"></div>
				<div class="info-content-banner">
					<div class="info-content-item" v-for="item in object.money_array" :key="item.name">
						<div class="info-content-title">{{ item.name }}</div>
						<div class="info-content-value" v-html="item.value"></div>
						<div class="info-content-line"></div>
					</div>
				</div>
			</div>
			<div id="MENU3" class="info-banner" v-if="productType == 1">
				<img src="../assets/kh/ico_qtxx@2x.png" class="info-icon" />
				<div class="info-title">其他信息</div>
				<div class="info-line"></div>
				<div class="info-content-banner">
					<div class="info-content-item" v-for="item in object.other_array" :key="item.name">
						<div class="info-content-title">{{ item.name }}</div>
						<div class="info-content-value" v-html="item.value"></div>
						<div class="info-content-line"></div>
					</div>
				</div>
			</div>

			<div id="MENU1" class="info-banner" v-if="productType == 2">
				<img src="../assets/kh/ico_jdjs@2x.png" class="info-icon" />
				<div class="info-title">景点介绍</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[0]"></div>
			</div>
			<div id="MENU2" class="info-banner" v-if="productType == 2">
				<img src="../assets/kh/ico_thzc@2x.png" class="info-icon" />
				<div class="info-title">特惠政策</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[1]"></div>
			</div>
			<div id="MENU3" class="info-banner" v-if="productType == 2">
				<img src="../assets/kh/ico_xxts@2x.png" class="info-icon" />
				<div class="info-title">信息提示</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[2]"></div>
			</div>
			<div id="MENU1" class="info-banner" v-if="productType == 2">
				<img src="../assets/kh/ico_xxts@2x.png" class="info-icon" />
				<div class="info-title">温馨提示</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[3]"></div>
			</div>
			<div id="MENU1" class="info-banner" v-if="productType == 2">
				<img src="../assets/kh/ico_jtzn@2x.png" class="info-icon" />
				<div class="info-title">交通指南</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.ticket_detail_array[4]"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getTicketDetail,
		getGroupDetail,
		getShipDetail,
		getDetailCode,
		getGoodsInfo
	} from "@/api/index";
	export default {
		data() {
			return {
				show: false,
				wx_code: "",
				activeIndex: "1",
				now_image_page: 1,
				now_image_index: 0,
				object: {
					theme_array: [], //主题
					isfree: false, //是否免费
					confirm: 1, //一二次确认
					imgArray: [], //图片
					title: "", //标题
					price: 0, //价格
					id: 0, //编号
					service_tag: "", //服务
					service_type: "", //二次确认
					service_content: "", //对应描述
					basic_info: "", //基本信息
					product_desc: "", //产品概括
					money_array: [], //费用说明
					other_array: [], //其他信息
					trip_array: [], //行程介绍
					ticket_detail_array: [], //门票 详情 0特惠政策 1信息提示 2温馨提示 3交通指南
				},
				productId: 0, //产品ID
				productType: 0, //1跟团 2周边 3游轮
				org: Number(this.$route.query.org)
			};
		},
		created() {
			this.productId = Number(this.$route.query.id);
			this.productType = Number(this.$route.query.type);
			if (this.productType == 1) {
				//跟团游 游轮
				this.getGroupDetail();
			} else if (this.productType == 2) {
				//周边游
				this.getTicketDetail();
			} else if (this.productType == 60) {
				//周边游
				this.getGoodsInfo();
			} else {
				//游轮
				this.getShipDetail();
			}
		},
		methods: {
			clickBuy() {
				if (this.wx_code != "" || this.object.price == 0) return;

				let app;
				if (this.$app == "kh") app = 3;
				else if (this.$app == "dy") app = 2;

				if (this.productType == 1) {
					getDetailCode(
						this.productId + ",1,0",
						"pages/particular/particular",
						app
					).then((res) => {
						this.wx_code = res;
					});
				} else if (this.productType == 2) {
					getDetailCode(
						this.productId + ",1,0",
						"pagesAround/aroundPage/productPage/productPage",
						app
					).then((res) => {
						this.wx_code = res;
					});
				} else if (this.productType == 3) {
					getDetailCode(
						this.productId + ",0",
						"pagesCruise/pages/CruiseDetail/CruiseDetail",
						app
					).then((res) => {
						this.wx_code = res;
					});
				} else if (this.productType == 60) {
					getDetailCode(
						this.productId,
						"pages/detail/detail",
						9
					).then((res) => {
						this.wx_code = res;
					});
				}
			},
			handleSelect(e) {
				console.log(e);
				if (this.productType == 1) {
					if (e == "1") {
						document.getElementById("MENU1").scrollIntoView();
					} else if (e == "2") {
						document.getElementById("MENU2").scrollIntoView();
					} else if (e == "3") {
						document.getElementById("MENU3").scrollIntoView();
					}
				} else if (this.productType == 3) {
					if (e == "1") {
						document.getElementById("MENU1").scrollIntoView();
					} else if (e == "2") {
						document.getElementById("MENU2").scrollIntoView();
					}
				} else if (this.productType == 60) {
					if (e == "1") {
						document.getElementById("MENU1").scrollIntoView();
					} else if (e == "2") {
						document.getElementById("MENU2").scrollIntoView();
					}
				} else {
					if (e == "1") {
						document.getElementById("MENU1").scrollIntoView();
					} else if (e == "2") {
						document.getElementById("MENU2").scrollIntoView();
					} else if (e == "3") {
						document.getElementById("MENU3").scrollIntoView();
					} else if (e == "4") {
						document.getElementById("MENU4").scrollIntoView();
					} else if (e == "5") {
						document.getElementById("MENU5").scrollIntoView();
					}
				}
			},
			clickImage(e) {
				this.now_image_index = e;
			},
			clickArrow(e) {
				if (e == 1) {
					//左箭头
					if (this.now_image_page > 0) this.now_image_page--;
				} else {
					//右箭头
					if (this.now_image_page < Math.ceil(this.object.imgArray.length / 5)) {
						this.now_image_page++;
					}
				}
			},
			getShipDetail() {
				getShipDetail(this.productId).then((res) => {
					let imgArray = [];
					res.data.images.forEach((e) => {
						let object = {
							isMp4: e.indexOf("mp4") != -1,
							url: e,
						};
						imgArray.push(object);
					});

					let highPointStr = "";
					let travelDays = "";
					let otherInfo = "";
					res.data.shipInfos.forEach((e) => {
						let value = e.value;
						let key = e.key;
						if (key == "产品亮点") {
							value.split(",").forEach((element) => {
								highPointStr += element + " ";
							});
						} else if (key == "邮轮") {
							otherInfo += "邮轮:" + value + " ";
						} else if (key == "行程天数") {
							travelDays = value;
						} else if (key == "出发地") {
							otherInfo += "出发地:" + value + " ";
						} else if (key == "目的地") {
							otherInfo += "目的地:" + value + " ";
						} else if (key == "返回地") {
							otherInfo += "返回地:" + value + " ";
						}
					});

					res.data.cost_Info.forEach((e, index) => {
						if (index == 0)
							this.object.money_array.push({
								name: "费用包含",
								value: e.content,
							});
						else if (index == 1)
							this.object.money_array.push({
								name: "费用不包含",
								value: e.content,
							});
						else if (index == 2)
							this.object.money_array.push({
								name: "温馨提示",
								value: e.content,
							});
						else if (index == 3)
							this.object.money_array.push({
								name: "其他说明",
								value: e.content,
							});
					});

					res.data.trip.forEach((e, i) => {
						let food =
							"早餐:" +
							e.breakfast +
							"  " +
							"午餐:" +
							e.lunch +
							"  " +
							"晚餐:" +
							e.dinner;

						let inn =
							"启航时间:" +
							e.setSailAt +
							" 抵达时间:" +
							e.arrivalAt +
							" 停止登船时间:" +
							e.stopBoardingAt;

						this.object.trip_array.push({
							title: "第" + e.sort + "天 " + e.title,
							info: e.details,
							food: food,
							inn: inn,
						});
					});
					this.object.product_desc = res.data.productOverview;
					this.object.basic_info =
						"● " + highPointStr + "<br/>● " + travelDays + "<br/>● " + otherInfo;
					this.object.id = res.data.id;
					this.object.isfree = false;
					this.object.imgArray = imgArray;
					this.object.title = res.data.productName;
					this.object.price = res.data.price;
					this.object.confirm = res.data.confirmType;
					this.object.service_tag = "服务";
					this.object.service_type =
						this.object.confirm == 1 ? "一次确认" : "二次确认";
					this.object.service_content =
						this.object.confirm == 1 ?
						"该商品在支付成功后即刻确认" :
						"该商品在支付成功需等待客服确认";
					this.object.theme_array = [{
							id: 1,
							url: require("../assets/kh/ico_cyrq@2x.png"),
							title: "出游人群",
							value: res.data.tags.theme == null ?
								"" : res.data.tags.theme.split(",")[0],
						},
						{
							id: 2,
							url: require("../assets/kh/ico_cyzt@2x.png"),
							title: "出游主题",
							value: res.data.tags.crowd == null ?
								"" : res.data.tags.crowd.split(",")[0],
						},
						{
							id: 3,
							url: require("../assets/kh/ico_sj@2x.png"),
							title: "报名时间",
							value: res.data.advanceEnrollDay == null ?
								"" : "提前" + res.data.advanceEnrollDay + "天报名",
						},
						{
							id: 4,
							url: require("../assets/kh/ico_cylx@2x.png"),
							title: "出游类型",
							value: res.data.tags.area == null ? "" : res.data.tags.area,
						},
					];
					this.show = true;
					console.log(this.object);
				});
			},
			getGoodsInfo() {
				getGoodsInfo(this.productId).then((res) => {
					console.log(res)
					let imgArray = [];
					let ob_mp4 = null;
					res.result.productList[0].pictureList.forEach((e) => {
						let object = {
							isMp4: e.url.indexOf("mp4") != -1,
							url: e.url,
						};
						imgArray.push(object);

						if (e.url.indexOf("mp4") != -1) ob_mp4 = JSON.stringify(object);
					});
					if (ob_mp4 != null) {
						imgArray.unshift(JSON.parse(ob_mp4));
						imgArray.splice(imgArray.length - 1, 1);
					}
					this.object.ticket_detail_array.push(
						res.result.productList[0].msg.length > 0 ? res.result.productList[0].msg[0].content :
						""
					);
					res.result.productList[0].details.forEach((e) => {
						this.object.other_array.push({
							name: e.title,
							value: e.content,
						});
					});
					this.object.id = res.result.productList[0].id;
					this.object.isfree = res.result.productList[0].isFree;
					this.object.imgArray = imgArray;
					this.object.title = res.result.productList[0].title;
					this.object.price = res.result.productList[0].price;
					this.object.confirm = res.result.productList[0].confirm;
					this.object.service_tag = "周边门票";
					this.object.service_type = res.result.productList[0].city;
					this.object.service_content =
						this.object.confirm == 1 ?
						"本产品展示价格即最终购买价格，您可直接下单购买。" :
						"此产品需要二次确认，您的甜程专属客服将在您下单后与您联系，请保持电话畅通。";

					let theme_index = 1;
					res.result.productList[0].highlights.split(";").forEach((e) => {
						this.object.theme_array.push({
							id: theme_index++,
							url: require("../assets/kh/ico_wjx@2x.png"),
							title: "",
							value: e,
						});
					});
					this.show = true;

					console.log(this.object);
				});
			},
			getTicketDetail() {
				getTicketDetail(this.productId).then((res) => {
					let imgArray = [];
					let ob_mp4 = null;
					res.result.productList[0].pictureList.forEach((e) => {
						let object = {
							isMp4: e.url.indexOf("mp4") != -1,
							url: e.url,
						};
						imgArray.push(object);

						if (e.url.indexOf("mp4") != -1) ob_mp4 = JSON.stringify(object);
					});
					if (ob_mp4 != null) {
						imgArray.unshift(JSON.parse(ob_mp4));
						imgArray.splice(imgArray.length - 1, 1);
					}
					this.object.ticket_detail_array.push(
						res.result.productList[0].msg.length > 0 ?
						res.result.productList[0].msg[0].content :
						"无",
						res.result.productList[0].details.length >= 1 ?
						res.result.productList[0].details[0].content :
						"无",
						res.result.productList[0].details.length >= 2 ?
						res.result.productList[0].details[1].content :
						"无",
						res.result.productList[0].details.length >= 3 ?
						res.result.productList[0].details[2].content :
						"无",
						res.result.productList[0].details.length >= 4 ?
						res.result.productList[0].details[3].content :
						"无"
					);
					this.object.id = res.result.productList[0].id;
					this.object.isfree = res.result.productList[0].isFree;
					this.object.imgArray = imgArray;
					this.object.title = res.result.productList[0].title;
					this.object.price = res.result.productList[0].price;
					this.object.confirm = res.result.productList[0].confirm;
					this.object.service_tag = "周边门票";
					this.object.service_type = res.result.productList[0].city;
					this.object.service_content =
						this.object.confirm == 1 ?
						"本产品展示价格即最终购买价格，您可直接下单购买。" :
						"此产品需要二次确认，您的甜程专属客服将在您下单后与您联系，请保持电话畅通。";

					let theme_index = 1;
					res.result.productList[0].highlights.split(";").forEach((e) => {
						this.object.theme_array.push({
							id: theme_index++,
							url: require("../assets/kh/ico_wjx@2x.png"),
							title: "",
							value: e,
						});
					});
					this.show = true;
					console.log(this.object);
				});
			},
			getGroupDetail() {
				let packagecompany = null
				if (this.$app == 'dy') {
					packagecompany = ",461,"
				} else if (this.$app == 'kh') {
					packagecompany = ",559,"
				}
				getGroupDetail(this.productId, packagecompany).then((res) => {
					let imgArray = [];
					let ob_mp4 = null;

					if (res.object.vedio_Json != null && res.object.vedio_Json != "") {
						let video_array = JSON.parse(res.object.vedio_Json);
						video_array.forEach((e) => {
							res.object.pepeatpic.push({
								picturePath: e.uri,
							});
						});
					}

					res.object.pepeatpic.forEach((e) => {
						let object = {
							isMp4: e.picturePath.indexOf("mp4") != -1,
							url: e.picturePath,
						};
						imgArray.push(object);

						if (e.picturePath.indexOf("mp4") != -1)
							ob_mp4 = JSON.stringify(object);
					});
					if (ob_mp4 != null) {
						imgArray.unshift(JSON.parse(ob_mp4));
						imgArray.splice(imgArray.length - 1, 1);
					}

					let highPointStr = "";
					let podstr = "";
					let destinationsStr = "";
					let podarr = res.object.pods;
					let destinationsArr = res.object.destinations;
					podarr.forEach((ele, i) => {
						if (i + 1 == podarr.length) {
							podstr = podstr + ele.pod;
						} else {
							podstr = podstr + ele.pod + "、";
						}
					});
					destinationsArr.forEach((ele, i) => {
						if (i + 1 == destinationsArr.length) {
							destinationsStr = destinationsStr + ele.destination;
						} else {
							destinationsStr = destinationsStr + ele.destination + "、";
						}
					});
					res.object.strengths.forEach((e) => {
						highPointStr += e.strength + " ";
					});

					res.object.instructions.forEach((e) => {
						this.object.money_array.push({
							name: e.title,
							value: e.content,
						});
					});

					res.object.others.forEach((e) => {
						this.object.other_array.push({
							name: e.title,
							value: e.content,
						});
					});

					if (res.object.package.length >= 1)
						res.object.package[0].schedules.forEach((e, i) => {
							let foodArray = JSON.parse(e.ext_Meals);
							let food =
								"早餐:" +
								(foodArray[0].Myself == 1 ? "自理" : foodArray[0].Detail) +
								"  " +
								"午餐:" +
								(foodArray[1].Myself == 1 ? "自理" : foodArray[1].Detail) +
								"  " +
								"晚餐:" +
								(foodArray[2].Myself == 1 ? "自理" : foodArray[2].Detail);

							let innArray = JSON.parse(e.ext_Hotel);
							let inn = "住宿:";
							if (typeof innArray == "object") inn += innArray.Details;
							else
								innArray.forEach((element, i2) => {
									if (i2 != innArray.length - 1) inn += element.Details + ",";
									else inn += element.Details;
								});

							this.object.trip_array.push({
								title: "第" + (i + 1) + "天 " + e.subject,
								info: e.info,
								food: food,
								inn: inn,
							});
						});
					this.object.product_desc = res.object.mark;
					this.object.basic_info =
						"● " +
						highPointStr +
						"<br/>● " +
						res.object.day +
						"天" +
						res.object.night +
						"夜" +
						"<br/>● 出发地:" +
						podstr +
						" 目的地:" +
						destinationsStr;
					this.object.id = res.object.id;
					this.object.isfree = false;
					this.object.imgArray = imgArray;
					this.object.title = res.object.title;
					this.object.price = res.object.refPrice;
					this.object.confirm = 2;
					this.object.service_tag = "服务";
					this.object.service_type = "二次确认";
					this.object.service_content = "该商品在线支付成功后需等待客服确认";
					this.object.theme_array = [{
							id: 1,
							url: require("../assets/kh/ico_cyrq@2x.png"),
							title: "出游人群",
							value: res.object.crowdcn.split(",")[0],
						},
						{
							id: 2,
							url: require("../assets/kh/ico_cyzt@2x.png"),
							title: "出游主题",
							value: res.object.themecn.split(",")[0],
						},
						{
							id: 3,
							url: require("../assets/kh/ico_sj@2x.png"),
							title: "报名时间",
							value: "提前" + res.object.advanceDay + "天报名",
						},
						{
							id: 4,
							url: require("../assets/kh/ico_cylx@2x.png"),
							title: "出游类型",
							value: res.object.isForeign == 1 ? "境外" : "境内",
						},
					];
					this.show = true;
					console.log(this.object);
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.trip-content /deep/ table {
		border: 1px solid #ccc;
		border-radius: 10px;
		padding: 10px;
	}

	.trip-content /deep/ td {
		border-bottom: 1px solid #ccc;
	}

	.detail-total /deep/ .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 4px solid var(--hover_color);
	}

	.detail-total /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
		border-bottom: 4px solid var(--hover_color);
	}

	.detail-total /deep/ .el-menu.el-menu--horizontal {
		border-bottom: 1px solid var(--hover_color);
	}

	.detail-total /deep/ .el-menu-item {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		padding-right: 12px;
	}

	.detail-total /deep/ .el-submenu__title {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		- padding-right: 12px;
	}

	.detail-total /deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		margin-left: 2px;
	}

	.detail-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		background-color: #fff;

		.tab-banner {
			position: sticky;
			top: 0px;
		}

		div.info-banner {
			display: flex;
			padding-top: 42px;
			padding-bottom: 42px;
			border-bottom: 1px solid #e6e6e6;

			img.info-icon {
				width: 36px;
				height: 36px;
				margin-top: 15px;
				object-fit: fill;
			}

			.info-title {
				margin-left: 6px;
				font-size: 18px;
				line-height: 22px;
				margin-top: 23px;
				width: 150px;
				color: #333333;
				opacity: 1;
			}

			div.info-line {
				margin-right: 51px;
				width: 1px;
				height: 67px;
				background-color: #e6e6e6;
				opacity: 1;
			}

			div.info-content {
				font-size: 14px;
				line-height: 30px;
				margin-top: 18px;
				color: #666666;
				width: 100%;
				opacity: 1;
			}

			.trip-null {
				margin-left: 10px;
				margin-top: 22px;
				font-size: 14px;
				line-height: 21px;
				width: 680px;
				color: #666666;
				opacity: 1;
			}

			div.trip-banner {
				display: flex;
				flex-direction: column;
				width: 100%;

				div.trip-item {
					display: flex;
					flex-direction: column;
					margin-top: 12px;

					.trip-line {
						width: 861px;
						height: 1px;
						background-color: #e6e6e6;
						opacity: 1;
					}

					div.trip-title-banner {
						display: flex;
						margin-bottom: 20px;

						img.trip-title-icon {
							width: 40px;
							height: 40px;
							object-fit: fill;
						}

						div.trip-title {
							font-size: 18px;
							line-height: 22px;
							font-weight: bold;
							width: 680px;
							color: #333333;
							opacity: 1;
							margin-left: 10px;
							margin-top: 10px;
						}

						div.trip-content {
							margin-left: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							opacity: 1;
						}

						div.trip-content-ship {
							margin-left: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							margin-top: 10px;
							opacity: 1;
						}

						div.trip-content2 {
							margin-left: 10px;
							margin-top: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							opacity: 1;
						}
					}
				}
			}

			div.info-content-banner {
				display: flex;
				flex-direction: column;
				margin-top: 22px;
				width: 100%;

				div.info-content-title {
					font-size: 18px;
					line-height: 22px;
					color: #4d4d4d;
					opacity: 1;
				}

				div.info-content-value {
					margin-top: 24px;
					font-size: 14px;
					line-height: 21px;
					color: #666666;
					opacity: 1;
				}

				/* table 样式 */
				div.info-content-value /deep/ table {
					border-top: 1px solid #ccc;
					border-left: 1px solid #ccc;
				}

				div.info-content-value /deep/ table td,
				div.info-content-value /deep/ table th {
					border-bottom: 1px solid #ccc;
					border-right: 1px solid #ccc;
					padding: 3px 5px;
				}

				div.info-content-value /deep/ table th {
					border-bottom: 2px solid #ccc;
					text-align: center;
				}

				div.info-content-line {
					margin-top: 36px;
					margin-bottom: 36px;
					width: 920px;
					height: 0px;
					border-bottom: 1px dashed #cccccc;
				}
			}
		}

		img:hover {
			cursor: pointer;
		}

		.content {
			width: 1200px;
			background-color: #fff;
			margin: 0 auto;

			div.top-bar {
				margin-top: 28px;
				display: flex;
				padding-bottom: 21px;

				div.group-theme {
					display: flex;

					div.item-theme {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-right: 10px;
						margin-top: 14px;

						img.item-theme-img {
							width: 44px;
							height: 36px;
							object-fit: fill;
						}

						div.item-theme-value {
							width: 75px;
							height: 18px;
							background: #f2f2f2;
							text-align: center;
							opacity: 1;
							border-radius: 2px;
							font-size: 12px;
							line-height: 18px;
							color: #333333;
						}

						div.item-theme-title {
							margin-top: 5px;
							font-size: 12px;
							line-height: 14px;
							color: #333333;
							opacity: 1;
						}
					}
				}

				div.ticket-theme {
					display: flex;
					flex-direction: column;
					margin-top: 25px;

					div.item-theme {
						display: flex;
						align-items: center;

						img.item-theme-img {
							width: 16px;
							height: 16px;
							object-fit: fill;
						}

						div.item-theme-value {
							margin-left: 20px;
							font-size: 14px;
							font-weight: 400;
							line-height: 36px;
							color: #333333;
							opacity: 1;
						}
					}
				}

				.prize {
					width: 670px;
					height: 28px;
					object-fit: fill;
				}

				.buy-icon {
					width: 670px;
					height: 64px;
					margin-top: 30px;
					margin-left: auto;
					object-fit: fill;
				}

				.btn-buy {
					width: 180px;
					height: 52px;
					background: #fe6f59;
					border-radius: 3px;
					text-align: center;
					font-size: 20px;
					font-weight: 400;
					line-height: 52px;
					color: #ffffff;
					margin-top: 50px;
					cursor: pointer;
				}

				.btn-buy-gray {
					width: 180px;
					height: 52px;
					background: #ccc;
					border-radius: 3px;
					text-align: center;
					font-size: 20px;
					font-weight: 400;
					line-height: 52px;
					color: #ffffff;
					margin-top: 50px;
					cursor: pointer;
				}

				div.left {
					display: flex;
					flex-direction: column;

					.image {
						width: 504px;
						height: 336px;
					}

					div.child-image-bar {
						display: flex;
						width: 504px;
						margin-top: 6px;

						.arrow-left {
							width: 21px;
							height: 64px;
							object-fit: fill;
						}

						.arrow-right {
							width: 21px;
							height: 64px;
							margin-left: 5px;
							object-fit: fill;
						}

						.child-image-array {
							display: flex;
							width: 504px;

							div.child-image {
								width: 87px;
								height: 64px;
								margin-left: 5px;
							}
						}
					}
				}

				div.right {
					display: flex;
					width: 670px;
					flex-direction: column;
					margin-left: 26px;

					.title {
						width: 670px;
						font-size: 21px;
						line-height: 25px;
						color: #4a4a4a;
						opacity: 1;
					}

					div.gray-bar {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						width: 100%;
						background: #fffbf3;

						div.price-bar {
							display: flex;
							padding-left: 16px;
							padding-bottom: 17px;
							border-bottom: 1px solid #f2e9d6;

							div.price-group {
								display: flex;
								margin-top: 21px;

								div.price-rmb {
									width: 43px;
									height: 24px;
									font-size: 18px;
									line-height: 22px;
									color: #666666;
									margin-top: 18px;
									opacity: 1;
								}

								div.price {
									height: 47px;
									font-size: 36px;
									line-height: 43px;
									color: #e32828;
									opacity: 1;
								}

								div.price-tag {
									width: 18px;
									height: 18px;
									margin-left: 5px;
									margin-top: 18px;
									font-size: 18px;
									line-height: 22px;
									color: #666666;
									opacity: 1;
								}
							}

							div.price-start-group {
								display: flex;
								margin-top: 41px;
								cursor: pointer;

								div.price-start {
									width: 56px;
									height: 14px;
									font-size: 14px;
									line-height: 17px;
									color: #fe6f59;
									opacity: 1;
									margin-left: 10px;
								}

								img.price-start-icon {
									margin-top: 3px;
									width: 13px;
									height: 13px;
									object-fit: fill;
									margin-left: 5px;
								}
							}

							div.pro-number {
								margin-top: 44px;
								margin-left: 10px;
								width: 57px;
								height: 12px;
								font-size: 12px;
								line-height: 14px;
								color: #5a5a5a;
								opacity: 1;
							}
						}

						div.service-bar {
							display: flex;
							margin-left: 16px;
							margin-top: 12px;
							margin-bottom: 10px;
							height: 18px;

							div.service-tag {
								font-size: 11px;
								line-height: 18px;
								color: #333333;
								padding-left: 8px;
								padding-right: 8px;
								background: #ffffff;
								border-radius: 2px 0px 0px 2px;
								opacity: 1;
							}

							div.service-type {
								height: 18px;
								background: #fe6f59;
								line-height: 18px;
								padding-left: 8px;
								padding-right: 8px;
								opacity: 1;
								border-radius: 0px 2px 2px 0px;
								font-size: 11px;
								color: #ffffff;
								opacity: 1;
							}

							div.service-content {
								font-size: 12px;
								margin-left: 10px;
								line-height: 18px;
								color: #989898;
								opacity: 1;
							}
						}
					}
				}
			}

			div.line {
				width: 100%;
				height: 20px;
				background: #f5f7f8;
				opacity: 1;
			}
		}
	}
</style>